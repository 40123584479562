.ui.borderless.menu {
	background-color: #1a1a1a;
	box-shadow: none;
	flex-wrap: wrap;
	border: none;
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
	color: white;
}

.bbs-header {
    font-family: 'VT323', monospace !important;
    background-color: #0000FF;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
	padding: 10px 0;
}

.bbs-header-container {
    width: 100%;
    max-width: 360px;
	display: flex;
	justify-content: space-between;
}

.bbs-header p {
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
}
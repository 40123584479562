.scroller {
    max-height: 45vh;
    padding: 0 0.25em;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    font-size: 12px;
}

.scroller p {
    margin-bottom: 0;
}
.bbs {
    font-family: 'VT323', monospace !important;
    background-color: #000000;
    color: #00ff00;
    width: 100%;
    min-width: 360px;
    min-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.bbs-container {
    width: 360px;
    /* border: solid red 0.5px; */
    padding: 0;
}

.bbs-container p {
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
}

.bbs-container p.small {
    font-family: 'Courier New', monospace;
    font-size: 10px;
    line-height: 10px;
}

.log-out-button {
    cursor: pointer;
}

.file-item {
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    padding: 20px 0 0 0;
}

.file-item p {
    display: inline-block;
    /* border: solid red 0.5px; */
}

